<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-chart-bar-stacked"    
    title="Lagerentwicklung"
      class="mb-5 pb-5"
      >  
      <v-row>
        <v-col cols="12">          
          <canvas class="pl-5 pr-5" id="lagerentwicklungLagerwertChart"></canvas>    
        </v-col>    
      </v-row>       
      <v-row>
        <v-col cols="12">          
          <canvas class="pl-5 pr-5 mb-10" id="lagerentwicklungPackungenChart"></canvas>    
        </v-col>    
      </v-row>  
      <v-row>
        <v-col cols="12">          
          <canvas class="pl-5 pr-5 mb-10" id="lagerentwicklungPznChart"></canvas>    
        </v-col>    
      </v-row> 
  </material-card>

</v-container>
</template>

<script>
  import axios from "axios";
  import Chart from 'chart.js/auto'
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  
  Chart.register(ChartDataLabels);

  export default {
    name: 'Lagerentwicklung',
    components: {
       
  },
  data() {
    return {
      chartPzn: null,
      chartPackungen: null,
      chartLagerwert: null,
      configPzn: {
        type: 'bar',        
        options: {
          plugins: {
            title: {
              display: true,
              text: 'PZN',
              font: {
                size: 22
              }
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              beginAtZero: true,
            }
          }
        },
        data: {
          labels: [
            
          ],
          datasets: [
            {
              label: 'RX',
              data: [],
              backgroundColor: "#31807A",
              datalabels: {
                align: 'center',
                anchor: 'center',
                labels: {
                  title: {
                    font: {
                      weight: 'bold'
                    }
                  },
                  value: {
                    color: 'white'
                  }
                },
                formatter: function(value) {
                  return value.toLocaleString();
                }
              }
            },
            {
              label: 'OTC',
              data: [],
              backgroundColor: "#2C3F60",
              datalabels: {
                align: 'center',
                anchor: 'center',
                labels: {
                  title: {
                    font: {
                      weight: 'bold'
                    }
                  },
                  value: {
                    color: 'white'
                  }
                },
                formatter: function(value) {
                  return value.toLocaleString();
                }
              }
            },
            {
              label: 'FW',
              data: [],
              backgroundColor: "#34AC8B",
              datalabels: {
                align: 'center',
                anchor: 'center',
                labels: {
                  title: {
                    font: {
                      weight: 'bold'
                    }
                  },
                  value: {
                    color: 'white'
                  }
                },
                formatter: function(value) {
                  return value.toLocaleString();
                }
              }
            },
          ]
        },
      },    
      configPackungen: {
        type: 'bar',        
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Packungen',
              font: {
                size: 22
              }
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              beginAtZero: true,
            }
          }
        },
        data: {
          labels: [
            
          ],
          datasets: [
            {
              label: 'RX',
              data: [],
              backgroundColor: "#31807A",
              datalabels: {
                align: 'center',
                anchor: 'center',
                labels: {
                  title: {
                    font: {
                      weight: 'bold'
                    }
                  },
                  value: {
                    color: 'white'
                  }
                },
                formatter: function(value) {
                  return value.toLocaleString();
                }
              }
            },
            {
              label: 'OTC',
              data: [],
              backgroundColor: "#2C3F60",
              datalabels: {
                align: 'center',
                anchor: 'center',
                labels: {
                  title: {
                    font: {
                      weight: 'bold'
                    }
                  },
                  value: {
                    color: 'white'
                  }
                },
                formatter: function(value) {
                  return value.toLocaleString();
                }
              }
            },
            {
              label: 'FW',
              data: [],
              backgroundColor: "#34AC8B",
              datalabels: {
                align: 'center',
                anchor: 'center',
                labels: {
                  title: {
                    font: {
                      weight: 'bold'
                    }
                  },
                  value: {
                    color: 'white'
                  }
                },
                formatter: function(value) {
                  return value.toLocaleString();
                }
              }
            },
          ]
        },
      },
      configLagerwert: {
        type: 'bar',        
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Lagerwert',
              font: {
                size: 22
              }
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              beginAtZero: true,
              ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value) {

                        return value.toLocaleString() + ' €';
                    }
                }
            }
          }
        },
        data: {
          labels: [
            
          ],
          datasets: [
            {
              label: 'RX',
              data: [],
              backgroundColor: "#31807A",
              datalabels: {
                align: 'center',
                anchor: 'center',
                labels: {
                  title: {
                    font: {
                      weight: 'bold'
                    }
                  },
                  value: {
                    color: 'white'
                  }
                },
                formatter: function(value) {
                  return value.toLocaleString() + " €";
                }
              }
            },
            {
              label: 'OTC',
              data: [],
              backgroundColor: "#2C3F60",
              datalabels: {
                align: 'center',
                anchor: 'center',
                labels: {
                  title: {
                    font: {
                      weight: 'bold'
                    }
                  },
                  value: {
                    color: 'white'
                  }
                },
                formatter: function(value) {
                  return value.toLocaleString() + " €";
                }
              }
            },
            {
              label: 'FW',
              data: [],
              backgroundColor: "#34AC8B",
              datalabels: {
                align: 'center',
                anchor: 'center',
                labels: {
                  title: {
                    font: {
                      weight: 'bold'
                    }
                  },
                  value: {
                    color: 'white'
                  }
                },
                formatter: function(value) {
                  return value.toLocaleString() + " €";
                }
              }
            },
          ]
        },
      }
    }
  },
  methods: {    
  },
  mounted() {
    var ctxPzn = document.getElementById('lagerentwicklungPznChart');
    ctxPzn.height = 300;
    this.chartPzn = new Chart(ctxPzn, this.configPzn);

    var ctxPackungen = document.getElementById('lagerentwicklungPackungenChart');
    ctxPackungen.height = 300;
    this.chartPackungen = new Chart(ctxPackungen, this.configPackungen);

    var ctxLagerwert = document.getElementById('lagerentwicklungLagerwertChart');
    ctxLagerwert.height = 300;
    this.chartLagerwert = new Chart(ctxLagerwert, this.configLagerwert);
  },
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/report/lagerentwicklung/" + apoId);    
      
      if (response) {     

          this.configPzn.data.labels = response.data.packungen.labels;         
          this.configPzn.data.datasets[0].data = response.data.pzn.rx;
          this.configPzn.data.datasets[1].data = response.data.pzn.otc;
          this.configPzn.data.datasets[2].data = response.data.pzn.fw;

          this.configPackungen.data.labels = response.data.packungen.labels;          
          this.configPackungen.data.datasets[0].data = response.data.packungen.rx;
          this.configPackungen.data.datasets[1].data = response.data.packungen.otc;
          this.configPackungen.data.datasets[2].data = response.data.packungen.fw;

          this.configLagerwert.data.labels = response.data.packungen.labels;         
          this.configLagerwert.data.datasets[0].data = response.data.lagerwert.rx;
          this.configLagerwert.data.datasets[1].data = response.data.lagerwert.otc;
          this.configLagerwert.data.datasets[2].data = response.data.lagerwert.fw;

          this.chartPzn.update();
          this.chartPackungen.update();
          this.chartLagerwert.update();
      }        
    } catch (e) {
      this.errors.push(e);
    }
  }
}

</script>