<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-chart-bar-stacked"    
    title="Lagerverteilung"
      class="mb-5 pb-5"
      >
      <v-row class="text-right">
        <v-col cols="3" class="ml-5">          
         
        </v-col>        
        <v-col cols="2">          
          <div class="text-h6">
            gesamt            
          </div>  
        </v-col>        
        <v-col cols="2">       
          <div class="text-h6">
            RX
          </div>       
         
        </v-col>        
        <v-col cols="2">     
          <div class="text-h6">
            OTC
          </div>         
         
        </v-col>        
        <v-col cols="2">      
          <div class="text-h6">
            FW
          </div>        
         
        </v-col>     
      </v-row>  
      <v-row>
        <v-col cols="3" class="ml-5">          
          {{ this.data[0].name }}
        </v-col>        
        <v-col cols="2" class="text-right">
            {{ toFixedIfNecessary(this.data[0].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right">   
          {{ toFixedIfNecessary(this.data[0].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right">  
          {{ toFixedIfNecessary(this.data[0].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right">  
          {{ toFixedIfNecessary(this.data[0].fw) }} €
        </v-col>     
      </v-row> 
      <v-row>
        <v-col cols="3" class="ml-5">
          <div style="padding-left: 30px">
            {{ this.data[1].name }}
          </div>
        </v-col>      
        <v-col cols="2" class="text-right">                    
            {{ toFixedIfNecessary(this.data[1].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right">       
          {{ toFixedIfNecessary(this.data[1].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[1].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[1].fw) }} €
        </v-col>     
      </v-row>     
      <v-row>
        <v-col cols="3" class="ml-5">
          <div style="padding-left: 60px">
            {{ this.data[2].name }}
          </div>
        </v-col>      
        <v-col cols="2" class="text-right">                    
            {{ toFixedIfNecessary(this.data[2].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right">       
          {{ toFixedIfNecessary(this.data[2].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[2].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[2].fw) }} €
        </v-col>     
      </v-row>     
      <v-row>
        <v-col cols="3" class="ml-5 font-italic font-weight-light">
          <div style="padding-left: 60px">
            {{ this.data[3].name }}
          </div>
        </v-col>      
        <v-col cols="2" class="text-right font-italic font-weight-light">                    
            {{ toFixedIfNecessary(this.data[3].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right font-italic font-weight-light">       
          {{ toFixedIfNecessary(this.data[3].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right font-italic font-weight-light">
          {{ toFixedIfNecessary(this.data[3].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right font-italic font-weight-light">
          {{ toFixedIfNecessary(this.data[3].fw) }} €
        </v-col>     
      </v-row>      
      <v-row>
        <v-col cols="3" class="ml-5">
          <div style="padding-left: 60px">
            {{ this.data[4].name }}
          </div>
        </v-col>      
        <v-col cols="2" class="text-right">                    
            {{ toFixedIfNecessary(this.data[4].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right">       
          {{ toFixedIfNecessary(this.data[4].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[4].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[4].fw) }} €
        </v-col>     
      </v-row>      
      <v-row>
        <v-col cols="3" class="ml-5 font-italic font-weight-light" font-italic>
          <div style="padding-left: 60px">
            {{ this.data[5].name }}
          </div>
        </v-col>      
        <v-col cols="2" class="text-right font-italic font-weight-light">                    
            {{ toFixedIfNecessary(this.data[5].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right font-italic font-weight-light">       
          {{ toFixedIfNecessary(this.data[5].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right font-italic font-weight-light">
          {{ toFixedIfNecessary(this.data[5].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right font-italic font-weight-light">
          {{ toFixedIfNecessary(this.data[5].fw) }} €
        </v-col>     
      </v-row>      
      <v-row>
        <v-col cols="3" class="ml-5">
          <div style="padding-left: 60px">
            {{ this.data[6].name }}
          </div>
        </v-col>      
        <v-col cols="2" class="text-right">                    
            {{ toFixedIfNecessary(this.data[6].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right">       
          {{ toFixedIfNecessary(this.data[6].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[6].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[6].fw) }} €
        </v-col>     
      </v-row>      
      <v-row>
        <v-col cols="3" class="ml-5 font-italic font-weight-light">
          <div style="padding-left: 60px">
            {{ this.data[7].name }}
          </div>
        </v-col>      
        <v-col cols="2" class="text-right font-italic font-weight-light">                    
            {{ toFixedIfNecessary(this.data[7].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right font-italic font-weight-light">       
          {{ toFixedIfNecessary(this.data[7].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right font-italic font-weight-light">
          {{ toFixedIfNecessary(this.data[7].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right font-italic font-weight-light">
          {{ toFixedIfNecessary(this.data[7].fw) }} €
        </v-col>     
      </v-row>      
      <v-row>
        <v-col cols="3" class="ml-5">
          <div style="padding-left: 60px">
            {{ this.data[8].name }}
          </div>
        </v-col>      
        <v-col cols="2" class="text-right">                    
            {{ toFixedIfNecessary(this.data[8].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right">       
          {{ toFixedIfNecessary(this.data[8].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[8].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[8].fw) }} €
        </v-col>     
      </v-row>      
      <v-row>
        <v-col cols="3" class="ml-5 font-italic font-weight-light">
          <div style="padding-left: 60px">
            {{ this.data[9].name }}
          </div>
        </v-col>      
        <v-col cols="2" class="text-right font-italic font-weight-light">                    
            {{ toFixedIfNecessary(this.data[9].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right font-italic font-weight-light">       
          {{ toFixedIfNecessary(this.data[9].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right font-italic font-weight-light">
          {{ toFixedIfNecessary(this.data[9].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right font-italic font-weight-light">
          {{ toFixedIfNecessary(this.data[9].fw) }} €
        </v-col>     
      </v-row>        
      <v-row>
        <v-col cols="3" class="ml-5">
          <div style="padding-left: 30px">
            {{ this.data[10].name }}
          </div>
        </v-col>      
        <v-col cols="2" class="text-right">                    
            {{ toFixedIfNecessary(this.data[10].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right">       
          {{ toFixedIfNecessary(this.data[10].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[10].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right">
          {{ toFixedIfNecessary(this.data[10].fw) }} €
        </v-col>     
      </v-row>        
      <v-row>
        <v-col cols="3" class="ml-5 font-italic font-weight-light">
          <div style="padding-left: 30px">
            {{ this.data[11].name }}
          </div>
        </v-col>      
        <v-col cols="2" class="text-right font-italic font-weight-light">                    
            {{ toFixedIfNecessary(this.data[11].gesamt) }} €
        </v-col>        
        <v-col cols="2" class="text-right font-italic font-weight-light">       
          {{ toFixedIfNecessary(this.data[11].rx) }} €
        </v-col>
        <v-col cols="2" class="text-right font-italic font-weight-light">
          {{ toFixedIfNecessary(this.data[11].otc) }} €
        </v-col>
        <v-col cols="2" class="text-right font-italic font-weight-light">
          {{ toFixedIfNecessary(this.data[11].fw) }} €
        </v-col>     
      </v-row>  
  </material-card>

</v-container>
</template>

<script>
  import axios from "axios";

  export default {
    name: 'Lagerverteilung',
    components: {
       
  },
  data() {
    return {
      data: null,      
    }
  },
  methods: {    
    toFixedIfNecessary( value ) {
      return value.toLocaleString("de", {   
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
    }
  },  
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/report/lagerverteilung/" + apoId);    
      
      if (response) {     
        this.data = response.data;
      }        
    } catch (e) {
      this.errors.push(e);
    }
  }
}

</script>