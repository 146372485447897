<template>
  <v-container id="regular-tables-view" fluid tag="section">    
<material-card
      icon="mdi-message-badge"
      icon-small
      title="Meldungen"
      color="primary"
    >
    <v-data-table
        :headers="headers"
        :items="messages"
        :items-per-page="15"
        class="pl-5 pr-5"
      >
        <template v-slot:item.datum="{ item }">
            {{ fromDateDisp(item.datum) }}
        </template>
        <template v-slot:item.apotheke="{ item }">
            {{ item.apotheke }}
        </template>
        <template v-slot:item.benutzer="{ item }">
            {{ item.benutzer }}
        </template>     
        <template v-slot:item.topic="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ item.topic }}</div>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.edit="{ item }">
          <v-icon @click="gotoItem(item)" class="mr-4">
            mdi-cog
          </v-icon>
        </template>   
      </v-data-table>
    </material-card>    
  </v-container>
</template>

<script>


import axios from "axios";

export default {
    name: 'AdministrationOverview',
    components: {
    
  },
  props: {
    
  },
  data() {
    return {
      headers: [
        {
          text: "Datum",
          align: "start",
          sortable: true,
          value: "datum",
        },
        {
          text: "Benutzer",
          align: "start",
          sortable: true,
          value: "benutzer",
        },
        {
          text: "Thema",
          align: "start",
          sortable: true,
          value: "topic",
        },
        {
          text: "Apotheke",
          align: "start",
          sortable: true,
          value: "apotheke",
        },
        {
          text: "Ort",
          align: "start",
          sortable: true,
          value: "ort",
        },
        { text: "", value: "edit", sortable: false, align: "end" },
      ],
      messages: [],
    }
  },
  methods: {
    fromDateDisp (date) {
        const datum = new Date(Date.parse(date));
        return datum.toLocaleDateString('de-DE');        
    },
    gotoItem(item) {
      this.$router.push("/Pharmacies?open=" + item.apothekeId);
    }
  },
  computed: {

  },
  async created() {
    this.$store.commit('setPageTitle', "Verwaltung");

    try {      
      const apiUrl = process.env.VUE_APP_API_URL;
      const responseMessages = await axios.get(apiUrl + "/apotheken/getMessages");    
          
      if (responseMessages) {     
        this.messages = responseMessages.data;  
      }

    } catch (e) {
      this.errors.push(e);
    }
  },
}
</script>
