<template>
  <v-container>
    <v-row>      
      <v-col cols="12">    
        <v-row v-for="message in model.messages" :key="message.id">
          <v-card
            class="mx-auto ma-2"
            width="600"
            max-width="600"
          >
            <v-card-text>
              <div>{{ fromDateDisp(message.datum) }}</div>
              <p>{{ message.benutzer }}</p>
              <p class="text-h4 text--primary">
                {{ message.topic }}
              </p>              
              <div class="text--primary">
                {{ message.text }}
              </div>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="mt-10">
          <v-card
            class="mx-auto ma-2"
            width="600"
            max-width="600"
          >
            <v-card-text>
              <p class="text-h3 text--primary">Eintrag hinzufügen</p>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Datum"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu2 = false"
                  locale="de-DE"
                ></v-date-picker>
              </v-menu>
          <v-select
          :items="model.topics"
          v-model="selectedTopicId"
          item-text="bezeichnung"
          item-value="id"
          label="Thema"
        ></v-select>
        <v-textarea
          label="Nachricht"
          v-model="messageText"
    ></v-textarea>
      </v-card-text>
    <v-card-actions>
      <v-btn color="primary" v-on:click="newMessage" :loading="loginWait"
      >
        Erstellen
      </v-btn>
    </v-card-actions>
          </v-card>
        </v-row>        
      </v-col>      
    </v-row>   
  </v-container>
</template>

<script>

import axios from "axios";

export default {
  name: "ApothekeMessages",
  props: {
    value: {
      type: Object,
    },
    filter: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
    topics: {      
      type: Object,
    }
  },
  data: () => ({        
    loginWait: false,    
    date: null,
    menu2: false,
    messageText: null,
    selectedTopicId: null,
   }),
  computed: {
    model: {
      get() {
        return this.value;
      },      
    },   
  }, 
  methods: {
    fromDateDisp (date) {
        const datum = new Date(Date.parse(date));
        return datum.toLocaleDateString('de-DE');        
    },
    async newMessage() {
      this.loginWait = true;

      if (this.date && this.selectedTopicId && this.messageText) {

      console.log('new mesaage: ' + this.messageText);
      
      var apoId = this.model.apothekeId;
      const apiUrl = process.env.VUE_APP_API_URL;

      var dto = {
        apothekeId: apoId,
        topicId: this.selectedTopicId,
        datum: this.date,
        text: this.messageText
      }

      await axios.post(apiUrl + "/apotheken/newMessage", dto);

      this.date = null,
      this.selectedTopicId = null;
      this.messageText = null;

      const responseMessages = await axios.get(apiUrl + "/apotheken/getMessages/" + apoId);    
          
      if (responseMessages) {     
        this.value.messages = responseMessages.data;  
      }
    }
    else {
      alert("Bitte vollständige Eingaben");
    }

      this.loginWait = false;
    }
  },
  async created() {
      
  },
};
</script>