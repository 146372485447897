<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-human-dolly"    
    title="Direktbevorratung"
      class="mb-5 pb-5"
      >      
      <v-row>
        <v-col cols="2">          
          <v-combobox
            class="pl-5"
              v-model="selectedAnbieter"
              :items="anbieterListe"
              item-text="name"
              item-value="name"
              label="Anbieter"
            clearable
          ></v-combobox>
          </v-col>
          <v-col cols="2">   
          <v-text-field
          label="Bevorratung in Tagen"
            v-model="anzahlTage"
            hide-details
            type="number"
          />
        </v-col>
        <v-col cols="2" class="pt-5">   
          <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn   
          v-bind="attrs"
          v-on="on"         
            fab
            small
            color="primary"
            @click="refreshList()"
          >
            <v-icon dark>
              mdi-file-document-refresh-outline
            </v-icon>
        </v-btn>
      </template>
      <span>Daten erzeugen</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-bind="attrs"
          v-on="on"     
            class="ml-3"
            fab
            small
            color="primary"
            @click="downloadArtikelliste()"
          >
            <v-icon dark>
              mdi-file-download-outline
            </v-icon>
          </v-btn>
        </template>
      <span>Daten als Excel-Datei herunterladen</span>
    </v-tooltip>

          </v-col>
      </v-row>
      <v-row>

        <v-col cols="12">          
          <v-data-table          
            :headers="headersTable"
            :items="tableValues"
            :items-per-page="50"
            class="ml-5 mr-5 mb-5"
            :footer-props="{
            showFirstLastPage: true,            
            'items-per-page-text':'Artikel pro Seite',
            'items-per-page-options': [25, 50, 75, 100, -1]
          }"
  >
  <template v-for="h in headersTable" v-slot:[`header.${h.value}`]="{ headersTable }">
              <v-tooltip bottom :key="h.text">
                <template v-slot:activator="{ on }">
                  <span v-html="h.text" v-on="on"></span>
                </template>
                <span>{{h.text}}</span>
              </v-tooltip>
            </template>
          <template
              v-for="header in headersTable.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
            <template slot="no-data">
    Keine Artikel vorhanden
  </template>
          </v-data-table>
        </v-col>
      </v-row> 
      </material-card>      

</v-container>
</template>

<script>
  import axios from "axios";

  export default {
    name: 'Direktbevorratung',
    components: {
       
  },
  data() {
    return {
      selectedAnbieter: null,
      anbieterListe: [],
      anzahlTage: 90,

      headersTable: [
        {
          text: "PZN",
          sortable: false,
          value: 'pzn',
          align: 'left',
          width: "8%"
        },
        {
          text: "Artikelname",
          sortable: false,
          value: 'artikelname',
          align: 'left'
        },
        {
          text: "Taxe EK",
          sortable: false,
          value: 'taxeEk',
          align: 'right',
          width: "15%",
          formatter: this.formatNumber
        },
        {
          text: "Lagerbestand",
          sortable: false,
          value: 'lagerbestand',
          align: 'right',
          width: "10%"
        },
        {
          text: "Bedarf",
          sortable: false,
          value: 'bedarf',
          align: 'right',
          width: "10%"
        },
        {
          text: "Bestellmenge",
          sortable: false,
          value: 'bestellmenge',
          align: 'right',
          width: "10%"
        },
        {
          text: "Bestellwert",
          sortable: false,
          value: 'bestellwert',
          align: 'right',
          width: "10%",
          formatter: this.formatNumber
        },
      ],
      tableValues: [],
      tableValues2: []
    }
  },
  methods: {
    toFixedIfNecessary( value, dp ){
      if (value >= 1000) {
        return value.toLocaleString();
      }
      else {
        return +parseFloat(value).toFixed( dp );
      }
    },
    formatNumber(value) {
      // Just an simple example, you can do anything here
      return value.toFixed(2) + ' €';
    },    
    async refreshList() {
      var apoId = parseInt(this.$route.params.id);

      const apiUrl = process.env.VUE_APP_API_URL;

      try {
        const response = await axios.post(apiUrl + "/report/direktbevorratungData", 
                                                      { 
                                                        apothekeId: apoId,
                                                        anbieter: this.selectedAnbieter.name,
                                                        anzahlTage: this.anzahlTage
                                                      });    
        
        if (response) {     
            this.tableValues = response.data;         
        }        
      } catch (e) {
        this.errors.push(e);
      }
    },
    async downloadArtikelliste() {
      var apoId = parseInt(this.$route.params.id);

      const apiUrl = process.env.VUE_APP_API_URL;

      var filename = "Direktbevorratung.xlsx";

      const config = { responseType: "blob" };

      await axios.post(apiUrl + "/report/direktbevorratungFile",
          { 
            apothekeId: apoId,
            anbieter: this.selectedAnbieter.name,
            anzahlTage: this.anzahlTage
          },
        config).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;

        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
  async mounted() {  
    const apiUrl = process.env.VUE_APP_API_URL;

      try {
        const response = await axios.get(apiUrl + "/report/hersteller");    
        
        if (response) {
            this.anbieterListe = response.data;         
        }
      } catch (e) {
        this.errors.push(e);
      }
  },
}

</script>