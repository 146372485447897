<template>
  <v-container>
    <v-row>      
      <v-col cols="12">
        <v-data-table          
            :headers="headersTable"
            :items="tableValues"
            :items-per-page="10"
            class="ml-5 mr-5 mb-5"    
            hide-default-footer
          >
          <template v-slot:item.name="{ item }">
          <span>{{ item.name }}</span>
        </template>
        <template v-slot:item.download="{ item }">
          <v-icon v-if="item.deletable" @click="deleteItemQuery(item)">
            mdi-delete
          </v-icon>
          <v-icon class="ml-3" @click="downloadItem(item)">
            mdi-download
          </v-icon>
        </template>
        </v-data-table>
      </v-col>      
    </v-row>
   
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ApothekeDownloads",
  props: {
    value: {
      type: Object,
    },
    filter: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data: () => ({        
        loginWait: false,
        
        headersTable: [
          {
          text: "Bezeichnung",
          sortable: true,
          value: 'bezeichnung',
        },
        { text: "", value: "download", sortable: false, align: "end" },
      ],
      tableValues: [
        {
          id: 1,
          bezeichnung: "Artikelliste",
          download: true
        },
        {
          id: 2,
          bezeichnung: "Besorger",
          download: true
        },
        {
          id: 3,
          bezeichnung: "Sichtwahl Auswertung",
          download: true
        },
        {
          id: 4,
          bezeichnung: "PZN-Liste mit letztem Lieferant",
          download: true
        }
      ],
   }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    fromDateDisp() {
        if(this.rechnungsdatum) {
          const datum = new Date(Date.parse(this.rechnungsdatum));
          return datum.toLocaleDateString('de-DE');
        }

        return null;
    },
  },
  methods: {
    async downloadItem(item) {
      if (item.id === 1) {
        await this.downloadArtikelliste("Artikelliste");
      }
      if (item.id === 2) {
        await this.downloadArtikelliste("Besorgerliste");
      }
      if (item.id === 3) {
        await this.downloadArtikelliste("Sichtwahl");
      }
      if (item.id === 4) {
        await this.downloadArtikelliste("LetzteLieferant");
      }

    },
    async downloadArtikelliste(controllername) {
      const apiUrl = process.env.VUE_APP_API_URL;

      var apoId = this.model.id;

      var filename = this.model.idf + "_" + controllername +"_" + this.formatNow() + ".xlsx";

      const config = { responseType: "blob" };
      await axios.get(apiUrl + "/apotheken/" + controllername.toLowerCase() + "/" + apoId, config).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;

        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    formatNow() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }
  },
};
</script>