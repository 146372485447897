<template>
  <v-container>
    <v-card    
    class="mx-auto"
  >
  <v-card-text>
    <p class="text-h4">Einlistungskriterien</p>
        <v-row>
          <v-col cols="1" class="mt-2">
        
      </v-col>
      <v-col cols="1" class="mt-5">
          Preisschwellen
      </v-col>
      <v-col cols="1">        
          <v-text-field
          label="Allgemein"
          v-model="model.einlistenPreisgrenzeAllgemein"
          prefix="€"    
          :disabled="isUser"
          :rules="[ rules.required ]"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          label="RX"
          v-model="model.einlistenPreisgrenzeRx"
          prefix="€"    
          :disabled="isUser"  
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          label="OTC"
          v-model="model.einlistenPreisgrenzeOtc"
          prefix="€"     
          :disabled="isUser"  
          :rules="[ rules.required ]"      
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          label="FW"
          v-model="model.einlistenPreisgrenzeFw"
          prefix="€"     
          :disabled="isUser" 
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">

      </v-col>
      <v-col cols="1" class="mt-2">
        1) In den letzten
      </v-col>
      <v-col cols="1">
        <v-text-field   
          dense          
          v-model="model.einlistenLangZeitraum"   
          :disabled="isUser"
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="mt-2">
        Tagen gab es mindestens 
      </v-col>
      <v-col cols="1">
        <v-text-field  
          dense      
          v-model="model.einlistenLangAnzahl"     
          :disabled="isUser"     
          :rules="[ rules.required ]"                 
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="mt-2">
        Nachfragen mit insgesamt
      </v-col>
      <v-col cols="1">
        <v-text-field   
          dense   
          v-model="model.einlistenLangMenge"      
          :disabled="isUser"        
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="mt-2">
        Packungen
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" class="mt-2">
        ODER
      </v-col>
      <v-col cols="1" class="mt-2">
        2) In den letzten
      </v-col>
      <v-col cols="1">
        <v-text-field  
          dense         
          v-model="model.einlistenKurzZeitraum"      
          :disabled="isUser"                 
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="mt-2">
        Tagen gab es mindestens 
      </v-col>
      <v-col cols="1">
        <v-text-field   
          dense     
          v-model="model.einlistenKurzAnzahl"       
          :disabled="isUser"       
          :rules="[ rules.required ]"                 
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="mt-2">
        Nachfragen mit insgesamt
      </v-col>
      <v-col cols="1">
        <v-text-field   
          dense        
          v-model="model.einlistenKurzMenge"       
          :disabled="isUser"          
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="mt-2">
        Packungen
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" class="mt-2">
        UND
      </v-col>
      <v-col cols="1" class="mt-2">
        3) In den letzten
      </v-col>
      <v-col cols="1">
        <v-text-field   
          dense        
          v-model="model.einlistenLetzteNachfrage"      
          :disabled="isUser"         
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="mt-2">
        Tagen wurde mindestens 
      </v-col>
      <v-col cols="1">
        <v-text-field   
          dense     
          value="1"               
          disabled="true"
          :rules="[ rules.required ]"                 
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="mt-2">
       Packung nachgefragt
      </v-col>
    </v-row>
  </v-card-text>
    </v-card>
    <v-card  
      class="mt-5"
    >
      <v-card-text>
        <p class="text-h4">Auslistungskriterien</p>
      
        <v-row>
          <v-col cols="1" class="mt-2">
        
      </v-col>
      <v-col cols="1" class="mt-5">
          Preisschwellen
      </v-col>
      <v-col cols="1">        
          <v-text-field
          label="Allgemein"
          v-model="model.auslistenPreisgrenzeAllgemein"  
          prefix="€"        
          :disabled="isUser"         
          :rules="[ rules.required ]"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          label="RX"
          v-model="model.auslistenPreisgrenzeRx"  
          prefix="€"       
          :disabled="isUser"         
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          label="OTC"
          v-model="model.auslistenPreisgrenzeOtc"  
          prefix="€"        
          :disabled="isUser"         
          :rules="[ rules.required ]"      
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          label="FW"
          v-model="model.auslistenPreisgrenzeFw"  
          prefix="€"       
          :disabled="isUser"         
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">

      </v-col>
      <v-col cols="1" class="mt-2">
        1) In den letzten
      </v-col>
      <v-col cols="1">
        <v-text-field   
          dense
          v-model="model.auslistenZeitraum"     
          :disabled="isUser"                 
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="mt-2">
        Tagen gab es weniger als 
      </v-col>
      <v-col cols="1">
        <v-text-field  
          dense      
          v-model="model.auslistenAnzahl"      
          :disabled="isUser"                 
          :rules="[ rules.required ]"                 
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="mt-2">
        Nachfrage mit weniger als
      </v-col>
      <v-col cols="1">
        <v-text-field   
          dense        
          v-model="model.auslistenMenge"        
          :disabled="isUser"               
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="mt-2">
        Packungen
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" class="mt-2">
        UND
      </v-col>
      <v-col cols="1" class="mt-2">
        2) In den letzten
      </v-col>
      <v-col cols="1">
        <v-text-field  
          dense         
          v-model="model.auslistenLetzteNachfrage"     
          :disabled="isUser"                   
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
      <v-col cols="4" class="mt-2">
        Tagen wurde keine Packungen nachgefragt.
      </v-col> 
    </v-row>
    <v-row>
      <v-col cols="1" class="mt-2">
        UND
      </v-col>
      <v-col cols="3" class="mt-2">
        3) Der Artikel wurde NICHT in den letzten
      </v-col>
      <v-col cols="1">
        <v-text-field   
          dense        
          v-model="model.auslistungsschutz"      
          :disabled="isUser"                
          :rules="[ rules.required ]"       
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="mt-2">
        Tagen an Lager genommen 
      </v-col>
    </v-row>    
  </v-card-text>
    </v-card>
    <v-row v-if="!isUser">      
        <v-col cols="3" class="mt-10 ml-4">     
            <v-btn color="primary" v-on:click="saveEinstellungenClick" :loading="einstellungenSaving"> Einstellungen speichern </v-btn>
            <p class="text-h4 mt-5" v-if="einstellungenSaved">Einstellungen gespeichert</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ApothekeListungsgrenzenEinstellungen",
  props: {
    value: {
      type: Object,
    },
  },
  data: () => ({
    rules: {
          required: value => !!value || 'Erforderlich',          
    },
    einstellungenSaving: false,
    einstellungenSaved: false,
   }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isUser() {
      return this.$store.getters.getRole === 'user';
    },
  },
  methods: {
    async saveEinstellungenClick() {

      this.einstellungenSaving = true;
      this.einstellungenSaved = false;

      const apiUrl = process.env.VUE_APP_API_URL;

      try {        

        const response = await axios.post(apiUrl + "/apotheken/saveListungsgrenzen", this.model);
        
        if (response) { 
          this.einstellungenSaved = true;
        }
      } catch (e) {
        this.errors.push(e);
      }
      this.einstellungenSaving=false;
    }
  }
};
</script>

<style scoped>
.v-text-field {
      width: 80px;
}
</style>