<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-text-field
              v-model="model.name"
              label="Name"
            ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
              v-model="model.kontaktEmail"
              label="E-Mail (Rechnungen & Kontakt)"
            ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
              v-model="model.strasse"
              label="Straße"
            ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
              v-model="model.nameZusatz"
              label="Name (Zusatz)"
            ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
              v-model="model.telefon"
              label="Telefon"
            ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
              v-model="model.plz"
              label="PLZ"
            ></v-text-field>
      </v-col>
    </v-row>    
    <v-row>
      <v-col cols="2">        
        <v-text-field
              v-model="model.kundennummer"
              label="Kundennummer"
            ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
              v-model="model.idf"
              label="IDF-Nummer"
            ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
              v-model="model.inhaber"
              label="Inhaber"
            ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
              v-model="model.ansprechpartner"
              label="Ansprechpartner"
            ></v-text-field>        
      </v-col>
      <v-col cols="4">
        <v-text-field
              v-model="model.ort"
              label="Ort"
            ></v-text-field>
      </v-col>
    </v-row>      
    <v-row>
      <v-col cols="4">
        <v-text-field
              v-model="model.betreuer"
              label="Betreuer"
            ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
              v-model="model.vermittler"
              label="Vermittler"
            ></v-text-field>
      </v-col>
      <v-col cols="4">
        
      </v-col>
    </v-row>         
    <v-row>
      <v-col cols="4">
        <v-switch
              v-model="model.mandatierungVorhanden"
              label="Mandatierung liegt vor"
    ></v-switch>
      </v-col>
      <v-col cols="4">
            
      </v-col>
      <v-col cols="4">
        
      </v-col>
    </v-row>       
    <v-row>
      <v-col cols="4">                 
        <v-switch
              v-model="model.mandatierungVerschickt"
              label="Mandatierung an WWS verschickt"
    ></v-switch>  
      </v-col>
      <v-col cols="4">
        <v-switch
              v-model="model.gekuendigt"
              label="Gekündigt"
    ></v-switch>
      </v-col>
      <v-col cols="4">
       
      </v-col>
    </v-row>   
    <v-row>
      <v-col cols="4">   
    <v-layout row wrap>
        <v-menu
      v-model="fromDateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
    <template v-slot:activator="{ on }">
        <v-text-field              
            :value="fromDateDisp"
            label="wawee! aktiv seit"
            readonly
            prepend-icon="mdi-calendar"
            v-on="on"
        ></v-text-field>
      </template>
        <v-date-picker
          locale="de-in"
          :min="minDate"
          v-model="model.onlineSeit"
          no-title
          @input="fromDateMenu = false"
        ></v-date-picker>
      </v-menu>
    </v-layout>    
      </v-col>     
      <v-col cols="4">
        <v-layout row wrap>
        <v-menu
      v-model="fromDateMenuGekuendigt"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
    <template v-slot:activator="{ on }">
        <v-text-field              
            :value="fromDateDispGekuendigt"
            label="Gekündigt zum"
            readonly
            prepend-icon="mdi-calendar"
            v-on="on"
        ></v-text-field>
      </template>
        <v-date-picker
          locale="de-in"
          :min="minDate"
          v-model="model.gekuendigtZum"
          no-title
          @input="fromDateMenuGekuendigt = false"
        ></v-date-picker>
      </v-menu>
    </v-layout>
      </v-col>
      <v-col cols="4">
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ApothekeAllgemein",
  props: {
    value: {
      type: Object,
    },
    filter: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data: () => ({
    fromDateMenu: false,
    fromDateMenuGekuendigt: false,
     minDate: "2022-06-01"     
   }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    fromDateDisp() {
        if(this.model.onlineSeit) {
          const datum = new Date(Date.parse(this.model.onlineSeit));
          return datum.toLocaleDateString('de-DE');
        }

        return null;
    },
    fromDateDispGekuendigt() {
        if(this.model.gekuendigtZum) {
          const datum = new Date(Date.parse(this.model.gekuendigtZum));
          return datum.toLocaleDateString('de-DE');
        }

        return null;
    }
  },
};
</script>