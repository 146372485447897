import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Layout from '@/layouts/default/Index'
import Home from '@/views/Dashboard.vue'
import Login from '@/views/UserLogin.vue'
import PharmaciesOverview from '@/views/PharmaciesOverview.vue'
import LagerentwicklungView from '@/views/Lagerentwicklung.vue'
import LieferfaehigkeitEntwicklungView from '@/views/LieferfaehigkeitEntwicklungView.vue'
import LagerverteilungView from '@/views/LagerverteilungView.vue'
import VerteilungBesorgerView from '@/views/VerteilungBesorgerView.vue'
import WareneingangEntwicklungView from '@/views/WareneingangEntwicklungView.vue'
import VerkaufVerteilungView from '@/views/VerkaufVerteilungView.vue'
import LagerumschlagEntwicklungView from '@/views/LagerumschlagEntwicklungView.vue'
import AbgegebenePackungenEntwicklungView from '@/views/AbgegebenePackungenEntwicklungView.vue'
import NewPasswordView from '@/views/NewPasswordView.vue'
import ResetPasswordView from '@/views/ResetPasswordView.vue'
import ResetNewPasswordView from '@/views/ResetNewPasswordView.vue'
import UserAdministration from '@/views/UserAdministration.vue'
import FirstPasswordView from '@/views/FirstPasswordView.vue'
import Lagerorte from '@/views/EinstellungLagerortView.vue'
import EinstellungKuehlBtm from '@/views/EinstellungKuehlBtmView.vue'
import AdministrationOverview from '@/views/AdministrationOverview.vue'
import EinstellungListungsgrenzenView from '@/views/EinstellungListungsgrenzenView.vue'
import DirektbevorratungView from '@/views/Direktbevorratung.vue'




Vue.use(VueRouter)

/* eslint-disable no-unused-vars */
function requireAuth(to, from, next) {
  if (!Vue.prototype.$http.defaults.headers.common["Authorization"]) {
    let token = store.state.user.token;
    if (token == '') {
      next({
      name: "Login",
    });
    }
    else {        
      if(!store.getters.getLoginState) {
        next({
          name: "Login",
        });
      }
      else {
        Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer  " + token;
      }
    }
  }

  if (to.meta.roles != null) {
    let access = false;      
    let userRole = store.getters.getRole;
    
      if(to.meta.roles.includes(userRole)) {
        access = true;
        next();
        return;
      }
      else
      {
        next({
          name: "Home",
        });
      }      
  } 
  else {
    next();
  }
}
/* eslint-enable no-unused-vars */

const routes = [  
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/:id',
    name: 'Home',
    component: Home,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: Home,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { layout: Layout }
  },
  {
    path: '/pharmacies',
    name: 'Pharmacies',
    component: PharmaciesOverview,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/lagerentwicklung/:id',
    name: 'Lagerentwicklung',
    component: LagerentwicklungView,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/lieferfaehigkeitEntwicklung/:id',
    name: 'Entwicklung Lieferfaehigkeit',
    component: LieferfaehigkeitEntwicklungView,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/lagerverteilung/:id',
    name: 'Lagerverteilung',
    component: LagerverteilungView,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/verteilungBesorger/:id',
    name: 'Verteilung Besorger',
    component: VerteilungBesorgerView,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/wareneingangEntwicklung/:id',
    name: 'Entwicklung Wareneingang',
    component: WareneingangEntwicklungView,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/verkaufVerteilung/:id',
    name: 'Verteilung Verkäufe',
    component: VerkaufVerteilungView,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/lagerumschlag/:id',
    name: 'Lagerumschlag',
    component: LagerumschlagEntwicklungView,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/abgegebenePackungen/:id',
    name: 'AbgegebenePackungen',
    component: AbgegebenePackungenEntwicklungView,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/lagerorte/:id',
    name: 'Lagerorte',
    component: Lagerorte,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/kuehlbtm/:id',
    name: 'Kühl / BTM',
    component: EinstellungKuehlBtm,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/newPassword',
    name: 'NewPassword',
    component: NewPasswordView,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  { 
    path: "*",
    name: 'Home',
    component: Home,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  { 
    path: "/resetPassword",
    name: 'ResetPassword',
    component: ResetPasswordView,
    meta: { 
      layout: Layout,      
    },
  },
  { 
    path: "/resetNewPassword",
    name: 'ResetNewPassword',
    component: ResetNewPasswordView,
    meta: { 
      layout: Layout,      
    },
  },
  { 
    path: "/firstPassword",
    name: 'FirstPassword',
    component: FirstPasswordView,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  { 
    path: "/useradministration",
    name: 'UserAdministration',
    component: UserAdministration,
    meta: { 
      layout: Layout,      
      roles: ['admin']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/administrationOverview',
    name: 'AdministrationOverview',
    component: AdministrationOverview,
    meta: { 
      layout: Layout,      
      roles: ['admin']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/listungsgrenzen/:id',
    name: 'EinstellungListungsgrenzenView',
    component: EinstellungListungsgrenzenView,
    meta: { 
      layout: Layout,  
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/direktbevorratung/:id',
    name: 'Direktbevorratung',
    component: DirektbevorratungView,
    meta: { 
      layout: Layout,      
      roles: ['user', 'admin', 'supervisor']
    },
    beforeEnter: requireAuth,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router