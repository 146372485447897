import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import i18n from './i18n'
import store from './store'
import axios from 'axios'
import { sync } from 'vuex-router-sync'

Vue.config.productionTip = false
Vue.prototype.$http = axios;

sync(store, router)

Vue.prototype.$eventHub = new Vue(); // Global event bus

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
