<template>
  <v-container>
    <material-card    
      color="primary" 
      icon="mdi-cog-outline"    
      title="Listungsgrenzen"
        class="mb-5 pb-5"
        >
        <v-row>
          <v-col cols="12">
            <ApothekeListungsgrenzenEinstellungen
              v-model="listungskriterien"
            >
            </ApothekeListungsgrenzenEinstellungen>
          </v-col> 
        </v-row>
        </material-card>
  </v-container>
</template>

<script>
  import axios from "axios";
  import ApothekeListungsgrenzenEinstellungen from '@/components/ApothekeListungsgrenzenEinstellungen.vue';
  
  export default {
    name: 'EinstellungListungsgrenzenView',
    components: {
      ApothekeListungsgrenzenEinstellungen
  },
  data() {
    return {
      listungskriterien: {
        apothekeId: null,
        einlistenPreisgrenzeAllgemein: null,
        einlistenPreisgrenzeRx: null,
        einlistenPreisgrenzeOtc: null,
        einlistenPreisgrenzeFw: null,
        einlistenLangZeitraum: null,
        einlistenLangAnzahl: null,
        einlistenLangMenge: null,
        einlistenKurzZeitraum: null,
        einlistenKurzAnzahl: null,
        einlistenKurzMenge: null,
        einlistenLetzteNachfrage: null,
        auslistenPreisgrenzeAllgemein: null,
        auslistenPreisgrenzeRx: null,
        auslistenPreisgrenzeOtc: null,
        auslistenPreisgrenzeFw: null,
        auslistenZeitraum: null,
        auslistenAnzahl: null,
        auslistenMenge: null,
        auslistenLetzteNachfrage: null,
        auslistungsschutz: null,
      }
    }
  },
  methods: {
    
  },
  mounted() {
    
  },
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/apotheken/listungsgrenzen/" + apoId);    
      
      if (response) {     

          this.listungskriterien = response.data;  

      }        
    } catch (e) {
      this.errors.push(e);
    }
  },
}

</script>