import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import VueJwtDecode from "vue-jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {      
      token: ''
    },
    loggedIn: false,
    mini: false,
    drawer: null,
    drawerImage: true,    
    rtl: false,
    pageTitle: '',
    selectedApotheke: null,
    apotheken: null
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 3, secure: true }),
        removeItem: key => Cookies.remove(key),
      },
    }),
  ],
  getters: {
    getInitials(state) {

      // get token from localstorage
      if (state.user.token != '') {
        try {
          //decode token here and attach to the user object
          let decoded = VueJwtDecode.decode(state.user.token);
          if(!decoded.unique_name) {
            return "XX";
          }
          else if (decoded.unique_name.includes(' '))
          {
            const splittedName = decoded.unique_name.split(' ');
            return splittedName[0].charAt(0) + splittedName[1].charAt(0);
          }
          else {
            return decoded.unique_name.charAt(0);
          }

        } catch (error) {
          // return error in production env
          console.log(error, "error from decoding token");
        }
      }      
    },
    getLoginState(state) {

      // get token from localstorage
      if (!state.user.token) { return false}

        try {
          //decode token here and attach to the user object
          let decoded = VueJwtDecode.decode(state.user.token);
          if(!decoded.exp) {
            return false;
          }
          else {
            let d = new Date(0);
            d.setUTCSeconds(decoded.exp);
            return d > Date.now();
          }

        } catch (error) {
          // return error in production env
          console.log(error, "error from decoding token");
        }   
    },
    getUsername(state) {
      let result = "";

      // get token from localstorage
      if (state.user.token != '') {
        try {
          //decode token here and attach to the user object
          let decoded = VueJwtDecode.decode(state.user.token);
          result = decoded.unique_name;
        } catch (error) {
          // return error in production env
          console.log(error, "error from decoding token");
        }
      }

      return result;
    },
    getEmail(state) {
      let result = "";

      // get token from localstorage
      if (state.user.token != '') {
        try {
          //decode token here and attach to the user object
          let decoded = VueJwtDecode.decode(state.user.token);
          result = decoded.email;
        } catch (error) {
          // return error in production env
          console.log(error, "error from decoding token");
        }
      }

      return result;
    },
    getUserId(state) {
      let result = "";

      // get token from localstorage
      if (state.user.token != '') {
        try {
          //decode token here and attach to the user object
          let decoded = VueJwtDecode.decode(state.user.token);
          result = decoded.nameid;
        } catch (error) {
          // return error in production env
          console.log(error, "error from decoding token");
        }
      }

      return result;
    },
    getRole(state) {
      let result = "";

      // get token from localstorage
      if (state.user.token != '') {
        try {
          //decode token here and attach to the user object
          let decoded = VueJwtDecode.decode(state.user.token);
          result = decoded.role;
        } catch (error) {
          // return error in production env
          console.log(error, "error from decoding token");
        }
      }

      return result;
    },
    getApotheke(state) {      
      return state.selectedApotheke;
    },
  },
  mutations: {
    changeMini(state, newMini){
      state.mini = newMini;
    },
    changeDrawer(state, newDrawer){
      state.drawer = newDrawer;
    },
    changeDrawerImage(state, newDrawerImage){
      state.drawerImage = newDrawerImage;
    },
    changeToken(state, newToken) {
      state.user.token = newToken;
    },
    changeLoggedIn(state, newLoggedIn) {
      state.loggedIn = newLoggedIn;
      if(!newLoggedIn) {
        Vue.prototype.$http.defaults.headers.common["Authorization"] = null;
      }
    },
    setPageTitle(state, newTitle) {
      state.pageTitle = newTitle;
    },
    setSelectedApotheke(state, newApotheke) {
      if(newApotheke) {
        state.selectedApotheke = newApotheke;
        const datum = new Date(Date.parse(newApotheke.waweeAktivSeit));
        state.pageTitle = newApotheke.name + ' - (wawee! aktiv seit ' + datum.toLocaleDateString('de-DE') + ')';
      }
      else {
        state.selectedApotheke = null;
        state.pageTitle = "";
      }
    },
    setApotheken(state, apotheken) {
      if(apotheken) {
        state.apotheken = apotheken;
      }
      else {
        state.apotheken = null;
      }
    }
  },
  modules: {

  },
});
