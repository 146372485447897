<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-cog-outline"    
    title="Steuerung von Kühl- und/oder BTM-Artikeln"
      class="mb-5 pb-5"
      >
      <v-row>
        <v-col cols="2"></v-col>        
        <v-col cols="3">
          <p class="text-h4 mt-5">Steuerung durch wawee!</p>
        </v-col> 
        <v-col cols="3">
          <p class="text-h4 mt-5">Nur Mindestbestand auffüllen</p>
        </v-col> 
      </v-row>
      <v-row>
        <v-col cols="2">
          <p class="text-h4 ml-15 mt-5">Kühlartikel</p>
        </v-col>        
        <v-col cols="3">
          <v-switch
                 inset
              v-model="kuehlArtikel.optimierung"         
            ></v-switch>
        </v-col> 
        <v-col cols="3">
          <v-switch
                 inset
              v-model="kuehlArtikel.nurMindestbestandAuffuellen"              
              :disabled="kuehlArtikel.optimierung == false"
            ></v-switch>
        </v-col> 
      </v-row>
      <v-row>
        <v-col cols="2">
          <p class="text-h4 ml-15 mt-5">BTM-Artikel</p>
        </v-col>        
        <v-col cols="3">
          <v-switch
                 inset
              v-model="btmArtikel.optimierung"              
            ></v-switch>
        </v-col> 
        <v-col cols="3">
          <v-switch
                 inset
              v-model="btmArtikel.nurMindestbestandAuffuellen"              
              :disabled="btmArtikel.optimierung == false"
            ></v-switch>
        </v-col> 
      </v-row>
      </material-card>
</v-container>
</template>

<script>
  import axios from "axios";
  
  export default {
    name: 'EinstellungKuehlBtmView',
    components: {
       
  },
  data() {
    return {
      kuehlArtikel: {
        optimierung: false,
        nurMindestbestandAuffuellen: false
      },
      btmArtikel: {
        optimierung: false,
        nurMindestbestandAuffuellen: false
      },
    }
  },
  methods: {
    
  },
  mounted() {
    
  },
  computed: {
    kuehlArtikelOptimierung() {
      return this.kuehlArtikel.optimierung
    },
    kuehlArtikelNurMindestbestandAuffuellen() {
      return this.kuehlArtikel.nurMindestbestandAuffuellen
    },
    btmArtikelOptimierung() {
      return this.btmArtikel.optimierung
    },
    btmArtikelNurMindestbestandAuffuellen() {
      return this.btmArtikel.nurMindestbestandAuffuellen
    },
  },
  watch: {
    kuehlArtikelOptimierung(newValue) {
      if (newValue === false) {
        this.kuehlArtikel.nurMindestbestandAuffuellen = false;
      }
      console.log('kuehlArtikelOptimierung: ' + newValue);

      var apoId = parseInt(this.$route.params.id);
      const apiUrl = process.env.VUE_APP_API_URL;

      var dto = {
        apothekeId: apoId,
        kuehlOptimiert: newValue,
        kuehlNurZumMindestbestand: false,
        btmOptimiert: false,
        btmNurZumMindestbestand: false
      }

      axios.post(apiUrl + "/apotheken/setKuehlOptimiert", dto);

    },
    kuehlArtikelNurMindestbestandAuffuellen(newValue) {
      console.log('kuehlArtikelNurMindestbestandAuffuellen: ' + newValue);

      var apoId = parseInt(this.$route.params.id);
      const apiUrl = process.env.VUE_APP_API_URL;

      var dto = {
        apothekeId: apoId,
        kuehlOptimiert: false,
        kuehlNurZumMindestbestand: newValue,
        btmOptimiert: false,
        btmNurZumMindestbestand: false
      }

      axios.post(apiUrl + "/apotheken/setKuehlNurMindestbestandAufuellen", dto);
    },
    btmArtikelOptimierung(newValue) {
      if (newValue === false) {
        this.btmArtikel.nurMindestbestandAuffuellen = false;
      }
      console.log('btmArtikelOptimierung: ' + newValue);

      var apoId = parseInt(this.$route.params.id);
      const apiUrl = process.env.VUE_APP_API_URL;

      var dto = {
        apothekeId: apoId,
        kuehlOptimiert: false,
        kuehlNurZumMindestbestand: false,
        btmOptimiert: newValue,
        btmNurZumMindestbestand: false
      }

      axios.post(apiUrl + "/apotheken/setBtmOptimiert", dto);
    },
    btmArtikelNurMindestbestandAuffuellen(newValue) {      
      console.log('btmArtikelNurMindestbestandAuffuellen: ' + newValue);    

      var apoId = parseInt(this.$route.params.id);
      const apiUrl = process.env.VUE_APP_API_URL;

      var dto = {
        apothekeId: apoId,
        kuehlOptimiert: false,
        kuehlNurZumMindestbestand: false,
        btmOptimiert: false,
        btmNurZumMindestbestand: newValue
      }

      axios.post(apiUrl + "/apotheken/setBtmNurMindestbestandAufuellen", dto);  
    }    
  },
  async created() {
    
    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/apotheken/kuehlBtm/" + apoId);    
      
      if (response) {     

          this.kuehlArtikel.optimierung = response.data.kuehlOptimiert;  
          this.kuehlArtikel.nurMindestbestandAuffuellen = response.data.kuehlNurZumMindestbestand;  

          this.btmArtikel.optimierung = response.data.btmOptimiert;  
          this.btmArtikel.nurMindestbestandAuffuellen = response.data.btmNurZumMindestbestand;  

      }        
    } catch (e) {
      this.errors.push(e);
    }    
  }
}

</script>