<template>
  <v-container>
    <v-form v-model="isFormValid">
    <material-card    
      color="primary" 
      icon="mdi-lock-reset"    
      title="Neues Passwort"
        class="mb-5 pb-5"
        >
        <v-row>
          <v-col cols="3" class="ml-5">          
            <v-text-field
              v-model="passwordnew1"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"            
              :type="show2 ? 'text' : 'password'"
              name="input-10-1"
              label="Neues Passwort"
              :rules="[rules.required, rules.min]"
              counter
              @click:append="show2 = !show2"
              required
            ></v-text-field>
          </v-col>        
        </v-row>     
        <v-row>
          <v-col cols="3" class="ml-5">          
            <v-text-field
              v-model="passwordnew2"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"            
              :type="show3 ? 'text' : 'password'"
              name="input-10-1"
              label="Neues Passwort wiederholen"
              :rules="[rules.required, rules.min, rules.emailMatch]"
              counter
              @click:append="show3 = !show3"
              required
            ></v-text-field>
          </v-col>        
        </v-row>      
        <v-row>
          <v-col cols="3" class="ml-5">     
            <v-btn color="primary" v-on:click="changePassword" :disabled="!isFormValid" :loading="passwordresetloading"> Passwort festlegen </v-btn>
            <p class="text-h4 mt-5" v-if="passwordChanged">Passwort geändert</p>
          </v-col>
        </v-row>     
    </material-card>
  </v-form>
  </v-container>
  </template>
  
  <script>
    import axios from "axios";
  
    export default {
      name: 'FirstPassword',
      components: {
         
    },
    data() {
      return {
        key: null,
        userId: null,
        isFormValid: false,
        passwordresetloading: false,
        passwordChanged: false,
        show2: false,
        show3: false,  
        passwordnew1: null,
        passwordnew2: null,
          rules: {
            required: value => !!value || 'Erforderlich',
            min: v => (v && v.length >= 8) || 'Mindestens 8 Zeichen',
            emailMatch: () => (this.passwordnew1 == this.passwordnew2) || (`Die Passwörter sind nicht identisch`),
          },  
      }
    },
    methods: {    
      async changePassword() {

        this.passwordresetloading=true;

        const apiUrl = process.env.VUE_APP_API_URL;
  
        
          try {
            var dto = {
              key: null,
              userId: -1,
              newPassword: this.passwordnew1
            };
      
            const response = await axios.post(apiUrl + "/administration/firstpassword", dto);
            
            if (response) { 
              if(response.data === true)    
              {
                this.$router.push("/dashboard");
              }
              else {
                this.passwordChanged = false;
              }
            }
          } catch (e) {
            this.errors.push(e);
          }
          this.passwordresetloading=false;
        }
      
    }, 
    async created() {
      this.$store.commit('setPageTitle', "Neues Passwort festlegen");
      this.key = this.$route.query.key;      
      this.userId = this.$route.query.id;
    }  
  }
  
  </script>