var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('material-card',{staticClass:"mb-5 pb-5",attrs:{"color":"primary","icon":"mdi-human-dolly","title":"Direktbevorratung"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-combobox',{staticClass:"pl-5",attrs:{"items":_vm.anbieterListe,"item-text":"name","item-value":"name","label":"Anbieter","clearable":""},model:{value:(_vm.selectedAnbieter),callback:function ($$v) {_vm.selectedAnbieter=$$v},expression:"selectedAnbieter"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Bevorratung in Tagen","hide-details":"","type":"number"},model:{value:(_vm.anzahlTage),callback:function ($$v) {_vm.anzahlTage=$$v},expression:"anzahlTage"}})],1),_c('v-col',{staticClass:"pt-5",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.refreshList()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-file-document-refresh-outline ")])],1)]}}])},[_c('span',[_vm._v("Daten erzeugen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.downloadArtikelliste()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-file-download-outline ")])],1)]}}])},[_c('span',[_vm._v("Daten als Excel-Datei herunterladen")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"ml-5 mr-5 mb-5",attrs:{"headers":_vm.headersTable,"items":_vm.tableValues,"items-per-page":50,"footer-props":{
            showFirstLastPage: true,            
            'items-per-page-text':'Artikel pro Seite',
            'items-per-page-options': [25, 50, 75, 100, -1]
          }},scopedSlots:_vm._u([_vm._l((_vm.headersTable),function(h){return {key:`header.${h.value}`,fn:function({ headersTable }){return [_c('v-tooltip',{key:h.text,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({domProps:{"innerHTML":_vm._s(h.text)}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.text))])])]}}}),_vm._l((_vm.headersTable.filter((header) =>
                header.hasOwnProperty('formatter')
              )),function(header){return {key:`item.${header.value}`,fn:function({ value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}})],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" Keine Artikel vorhanden ")])],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }